.root{
    position: relative;
    width: 100%;

    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.btn{
    display: inline-block;
    width: 48px;
    height: 32px;
    background: #333B47;
    border-radius: 4px;

    line-height: 32px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;

    cursor: pointer;
    vertical-align: middle;
}

.btn:hover{
    color: #000000;
    background-color: #EFBA0C;
}

.head{
    position: relative;
    width: 100%;
    height: 60px;
    background: #20262F;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    line-height: 60px;
}

.head .nr_crt{
    vertical-align: middle;
}

.head .address{
    vertical-align: middle;
    margin-left: 12px;
}

.token{
    position: relative;
    width: 100%;
    height: 60px;
    background: #20262F;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 10px;
    padding-top: 8px;
}

.token .row1,
.token .row2{
    display: inline-block;
    width: 100%;
}

.token .row2{
    opacity: 0.25;
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 5px;
}

.token .left{
    float: left;
}

.token .right{
    float: right;
}