.root{
    height: 100%;
    width: 100%;
}

.container {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 0px;

    padding-left: 16px;
    padding-right: 16px;
}

.btn{
    width: 60px;
    height: 32px;
    background: #333B47;
    border-radius: 4px;

    line-height: 32px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;

    margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;

}

.btn:hover{
    color: #000000;
    background-color: #EFBA0C;
}

.title{
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    padding-top: 30px;
    margin-bottom: 10px;
}

.accountBalance{
    padding-left: 16px;
    padding-right: 16px;
}

.actionBtn{
    display: inline-block;
    height: 32px;
    background: #333B47;
    border-radius: 4px;

    line-height: 32px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;

    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;

    padding-left: 12px;
    padding-right: 12px;

}

.actionBtn:hover{
    color: #000000;
    background-color: #EFBA0C;
}




table{
    width: 1600px;
    margin-top: 20px;
    table-layout: fixed;
}

caption{
    background: #161F2C;
    height: 24px;
    line-height: 24px;
}

th{
    opacity: 0.5;
    font-size: 12px;
    color: #FFFFFF;
    padding-top: 16px;
    padding-bottom: 2px;
    text-align: right;
}

td{
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: #FFFFFF;
    text-align: right;
}

tbody tr:hover {
    background-color: #161F2C;
}


table .col1{
    width: 140px;
    text-align: left;
    padding-left: 20px;
}

table .col2{
    width: 200px;
    text-align: left;
    padding-left: 20px;
}

table .col3{
    width: 200px;
}

table .col4{
    width: 200px;
}

table .col11{
    width: 400px;
    text-align: left;
    padding-left: 20px;
}
table .col12{
    text-align: left;
    padding-left: 20px;
}



/* .inputToken{

} */

.inputToken input{
    height: 36px;
    width: 500px;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
}

.inputToken select{
    height: 36px;
    width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    cursor: pointer;
    vertical-align: middle;
}
