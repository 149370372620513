.root{
    height: 100%;
    width: 100%;
}

.container {
    position: relative;
    max-width: 960px;
    height: 100%;
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 0px;
}

.title{
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    padding-top: 30px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 10px;
}

.actionBtn{
    display: inline-block;
    height: 32px;
    background: #333B47;
    border-radius: 4px;

    line-height: 32px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;

    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;

    padding-left: 12px;
    padding-right: 12px;

}

.actionBtn:hover{
    color: #000000;
    background-color: #EFBA0C;
}