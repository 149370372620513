.item{
    display: inline-block;
    width: 100%;
    /* height: 80px; */
    background-color: #20262F;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 4px;
    margin-top: 8px;
    cursor: pointer;
}

.row{
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 4px;
    width: 100%;
    float: left;
}

.col1{
    width: 25%;
    float: left;
    text-align: left;
    opacity: 0.5;
}

.col2{
    width: 75%;
    float: left;
    text-align: right;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}